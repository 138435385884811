<style scoped>
.column {
    background-color: #fff;
    border-radius: 6px;
    border: 1px solid #eaeaea;
    padding: 12px;
    margin: 10px 0px;
    overflow: auto;
}
.course_img {
    float: left;
    height: 150px;
    margin-right: 12px;
}
.title {
    /* font-weight: bold;
			font-size: larger;
			margin-right: 4px; */
    font-size: x-large;
    margin-right: 6px;
}
.tag {
    margin-right: 4px;
    background: #edeff2;
    border-radius: 4px;
    font-size: x-small;
    color: #667280;
    padding: 4px 8px;
}
.row {
    padding-top: 12px;
}
.row span {
    font-size: small;
}
.base_button {
    vertical-align: middle;
    background-color: #ffffff;
    border: 1px solid #d9ecff;
    border-radius: 4px;
    margin-right: 6px;
}
.base_button:hover {
    cursor: pointer;
    border: 1px solid #ff0000;
}
.img_button {
    padding-top: 4px;
}
.text_button {
    font-weight: bold;
    padding: 8px 12px;
}
.text_button_small {
    padding: 6px 10px;
    font-size: small;
}
.small_logo {
    height: 22px;
}
</style>

<template>
    <div>
        <div class="veadmin-iframe-header veadmin-iframe-header-custom-fixed">
            <span>当前位置：</span>
            课程管理
            <button class="veadmin-iframe-button" onclick="location.reload()">
                <i class="el-icon-refresh-right"></i>
            </button>
        </div>
        <div class="veadmin-iframe-content veadmin-iframe-content-custom-fixed">
            <div style="padding: 20px">
                <el-button type="primary" icon="el-icon-plus" plain @click="jumpTo('/admin/course/add')">新增课程</el-button>

                <el-card class="box-card" style="margin: 10px 0px" v-for="course in courseList" :key="course.id">
                    <div slot="header" class="clearfix">
                        <el-button icon="el-icon-edit" plain size="small" @click="jumpTo('/admin/course/edit?id=' + course.id)">修改</el-button>
                        <el-button type="danger" icon="el-icon-delete" plain size="small" @click="deleteCourseById(course.id)">删除</el-button>
                    </div>
                    <div class="text item">
                        <img :src="course.img_src" class="course_img" />
                        <a class="title" target="_blank">{{ course.title }}</a>
                        <span class="tag" v-for="tag in course.course_tag_list" :key="tag.id">{{ tag.name }}</span>
                        <el-button
                            icon="el-icon-plus"
                            type="primary"
                            size="small"
                            @click="jumpTo('/admin/course/setCourseTag?courseId=' + course.id + '&courseTitle=' + course.title)"
                            >设置标签</el-button
                        >
                        <div class="row">
                            <span style="font-size: small; color: #999">{{ course.profile }}</span>
                        </div>
                        <div class="row">
                            <span>发布平台：</span>
                            <button
                                class="base_button img_button"
                                @click="openNewWindow(platform.url)"
                                v-for="platform in course.course_platform_list"
                                :key="platform.id"
                            >
                                <img class="small_logo" :src="getPlatformImgSrcByPlatformName(platform.platform_name)" />
                            </button>
                            <el-button
                                icon="el-icon-plus"
                                type="primary"
                                size="small"
                                @click="jumpTo('/admin/course/setCoursePlatform?courseId=' + course.id + '&courseTitle=' + course.title)"
                                >设置发布平台</el-button
                            >
                        </div>
                        <div class="row">
                            <span>资料下载：</span>
                            <button class="base_button img_button" @click="openNewWindow(course.resource_url)">
                                <img class="small_logo" src="/pictures/platform/pan.baidu.logo.gif" />
                            </button>
                            <span v-if="course.extracted_code != ''" style="margin-left: 6px">提取码：{{ course.extracted_code }}</span>
                        </div>
                        <div class="row">
                            <span>推广：</span>
                            <!-- 腾讯云特惠
                            https://cloud.tencent.com/act/cps/redirect?redirect=2446&cps_key=00ed61e92748fbbbf799486a6bc05854&from=console -->
                            <!-- 阿里云特惠
                            https://www.aliyun.com/daily-act/ecs/activity_selection?userCode=zahxwqv2 -->
                            <button
                                class="base_button text_button_small"
                                v-for="ad in course.course_ad_list"
                                :key="ad.id"
                                @click="openNewWindow(ad.ad_url)"
                            >
                                <span style="color: #006eff">{{ ad.title }}</span>
                            </button>
                            <el-button
                                icon="el-icon-plus"
                                type="primary"
                                size="small"
                                @click="jumpTo('/admin/course/setCourseAd?courseId=' + course.id + '&courseTitle=' + course.title)"
                                >设置推广</el-button
                            >
                        </div>
                    </div>
                </el-card>
            </div>
        </div>
    </div>
</template>

<script>
import { apiListCourse } from "@/api/public/course.js";
import { apiDeleteCourseById } from "@/api/admin/course.js";

export default {
    data() {
        return {
            courseList: [],
            platformList: [
                {
                    name: "51CTO学堂",
                    imgSrc: "/pictures/platform/edu.51cto.logo.png"
                }
            ]
        };
    },
    methods: {
        // 获取文章列表
        async listCourse() {
            try {
                const response = await apiListCourse();
                if (response.data.code == 200) {
                    this.courseList = response.data.data;
                } else {
                    this.$message.error(response.data.message);
                }
            } catch (error) {
                console.log(error);
            }
        },
        async deleteCourseById(id) {
            let formData = new FormData();
            formData.append("token", this.$store.state.token);
            formData.append("id", id);

            //调用接口
            const response = await apiDeleteCourseById(formData);
            if (response.data.code === 200) {
                this.$message({
                    message: "删除成功",
                    type: "success"
                });
                setTimeout(function () {
                    location.reload();
                }, 1000);
            } else {
                this.$message.error(response.data.message);
            }
        },
        getPlatformImgSrcByPlatformName(platformName) {
            //使用find()方法，根据value查找列表中的对象。
            let platform = this.platformList.find(function (item) {
                return item.name === platformName;
            });
            return platform.imgSrc;
        },
        jumpTo(url) {
            window.location.href = url;
        },
        openNewWindow(url) {
            window.open(url);
        }
    },
    mounted() {
        this.listCourse();
    }
};
</script>
