import axios from "@/api/baseRequest.js";

//增加课程
export const apiAddCourse = (token, formData) =>
    axios.post("/api/1/admin/course/add", formData, {
        headers: {
            token: token
        }
    });

//修改课程
export const apiEditCourse = (token, formData) =>
    axios.post("/api/1/admin/course/edit", formData, {
        headers: {
            token: token
        }
    });

//删除课程
export const apiDeleteCourseById = (formData) => axios.post("/api/1/admin/course/deleteCourseById", formData);
