import axios from "@/api/baseRequest.js";

//列出课程
export const apiListCourse = () => axios.get("/api/1/pub/course/listCourse");

//根据ID获取课程
export const apiGetCourseById = function (id) {
    return axios.get("/api/1/pub/course/getCourseById", {
        params: {
            id: id
        }
    });
};
